
.btns button{
    padding:0 14px ;
    height:40px ;
    border:1px solid transparent ;
    background-color:transparent ;
    color:#fff ;
    opacity: 0.9;
    cursor: pointer;
    font-family: 'SF UI Text-Regular';


}
.btns button:not(:last-of-type){
    margin-right: 3px;
}
.btns button:hover{
    color:#62CEFB !important;
}
.btns button.active{
    border: 1px solid #4B4B4B;
    border-radius: 11px;

    color:#62CEFB !important;
}

.btns{
    display: flex;
    justify-content: center;
    flex-basis: 1;
    flex-wrap: wrap;
    @media screen and (max-width:750px) {
        width:340px;
        display: flex;
        display: -webkit-flex;
        display: -moz-box;
        display: -webkit-box;
        display: -ms-flexbox; 
        flex-wrap: wrap;
        
        justify-content: center;
        align-items: center;
    }
}

.btns button{
    padding:0 11px ;
}
.btns button:not(:last-of-type){
    margin-right: 1px;
}

.page2{
    box-sizing: border-box;
}
@media screen and (min-width:750px) {
    /* 设置滚动条的样式 */
::-webkit-scrollbar {
    width:4px;
    height: 4px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
border-radius:10px;
background-color: #F4F4F4;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(121,121,121,0.4);
}
}
