*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration-line: none;
 
}

body,html{
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
/* @media screen and (max-width: 450px) {
  body,html{
    height: 100%;
    overflow: auto;
  }
} */
@font-face {
  font-family:'SF UI Text-Bold';
  src: url('./assets/fonts/SFUIText-Bold.ttf');
}
@font-face {
  font-family:'SF UI Text-Light';
  src: url('./assets/fonts/SFUIText-Light.ttf');
}
@font-face {
  font-family:'SF UI Text-Regular';
  src: url('./assets/fonts/SFUIText-Regular.ttf');
}

@font-face {
  font-family:'SF UI Text-Medium';
  src: url('./assets/fonts/SFUIText-Medium.ttf');
}